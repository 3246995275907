<template>
  <div class="home">
    <div class="home-header">
      <img src="@/assets/logo.png" class="home-header-img" />
      <div class="home-header-right">
        <!-- <img src="@/assets/downico.png" />
        <div>Download</div> -->
        <a href="https://apps.apple.com/app/id6468986015" target="_blank">
          Download
        </a>
      </div>
    </div>
    <div class="home-line"></div>
    <div class="home-main">
      <div class="home-main-left">
        <div>
          <div class="home-main-lef-1">KaCha-Al Portrait Editor</div>
          <div class="home-main-lef-2">
            Your AI Lens, Embrace Portrait Limitless
          </div>
          <div class="home-main-left-link">
            <a
              class="home-main-left-link-1"
              target="_blank"
              href="https://apps.apple.com/app/id6468986015"
              >Ios App Store</a
            >
            <a
              class="home-main-left-link-2"
              href="https://play.google.com/store/apps/details?id=ai.infinity.net"
              target="_blank"
              >Android Store</a
            >
          </div>
        </div>
      </div>
      <img src="@/assets/img-main.png" class="home-main-right" />
    </div>
    <div class="home-line"></div>
    <div class="home-footer">
      ADDRESS: 20 EMERALD HILL ROAD SINGAPORE(229302)&nbsp;&nbsp; Mail:
      dev@astroinfinity.net.&nbsp;&nbsp;Telephone: +65 83075834
    </div>
  </div>
</template>

<script>
export default {
  name: "KachaHome",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
.home {
  width: 100vw;
  height: 100vh;
  background: #000000;
}
.home-container {
}
.home-line {
  border-bottom: 1px solid #ffffff;
  transform: scaleY(0.2);
}
.home-header {
  width: 90%;
  margin: 0 auto;
  height: 115px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-header-img {
  width: 62px;
  height: 62px;
}
.home-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.home-header-right a {
  display: block;
  padding-left: 24px;
  background: url("../assets/downico.png") no-repeat 0 center;
  background-size: 20px 20px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  cursor: pointer;
  text-decoration: none;
}
.home-header-right img {
  width: 16px;
  height: 16px;
  margin-right: 3px;
}
.home-header-right div {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
}
.home-main {
  width: 80%;
  box-sizing: border-box;
  margin: 16px auto;
  height: calc(100% - 223px);
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.home-main-left {
  height: 100%;
  display: flex;
  align-items: center;
}
.home-main-lef-1 {
  width: 100%;
  height: 40px;
  font-size: 44px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
  text-align: left;
  margin-bottom: 20px;
}
.home-main-lef-2 {
  width: 100%;
  height: 30px;
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  text-align: left;
  margin-bottom: 45px;
}
.home-main-left-link {
  display: flex;
  width: 100%;
}
.home-main-left-link .home-main-left-link-1 {
  background: url("../assets/apico.png") no-repeat 10px center, #fff;
  display: block;
  width: 170px;
  height: 60px;
  background-size: 22px 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 60px;
  margin-right: 30px;
  cursor: pointer;
  text-decoration: none;
}
.home-main-left-link .home-main-left-link-2 {
  background: url("../assets/pgico.png") no-repeat 10px center, #fff;
  display: block;
  width: 170px;
  height: 60px;
  background-size: 22px 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 60px;
  margin-right: 30px;
  cursor: pointer;
  text-decoration: none;
}
.home-footer {
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 16px;
}
</style>
